import { ReactTyped } from 'react-typed';
import { Reveal } from './Reveal';
import {
	Button,
	HeroDetail,
	HeroSubTitle,
	HeroWrapper,
	Section,
} from './styled/style';

interface IHero {
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Hero = ({ setIsModalOpen }: IHero) => {
	return (
		<Section withMargin id="hero">
			<HeroWrapper>
				<Reveal>
					<HeroSubTitle>
					Experience the Ultimate Shine, {' '}
						<span>
							<ReactTyped
								strings={[
									'Anytime!',
									'Anywhere!'
								]}
								typeSpeed={120}
								backSpeed={140}
								loop
							/>
						</span>
					</HeroSubTitle>
				</Reveal>
				<Reveal>
					<HeroDetail>
						<p className="highlightFirstLetter">Welcome to Ultra Shine Mobile Wash, your premier choice for maintaining the 
						pristine appearance of your fleet. We specialize in delivering on-demand, top-quality cleaning 
						solutions directly to your fleet, ensuring each vehicle shines with professionalism and 
						pride. Our dedicated team is equipped with state-of-the-art 
						equipment and industry-leading techniques to deliver superior results. <br/><br/>

						Whether you have a small fleet or a large fleet, we offer flexible scheduling and reliable 
						service to accomodate your operational needs.<br/><br/>

						Experience convenience and excellence with Ultra Shine Mobile Wash, where cleanliness meets 
						efficiency, every time.<br/>
						Let's connect!</p>
					</HeroDetail>
				</Reveal>
				<Reveal>
					<Button onClick={() => setIsModalOpen(true)}>Contact Us</Button>
				</Reveal>
			</HeroWrapper>
		</Section>
	);
};

export default Hero;
