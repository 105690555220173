import { projects } from '../utils/constant';
import { Reveal } from './Reveal';
import {
	Header,
	HeaderLine,
	HeaderTitle,
	ServiceTech,
	ServiceDescription,
	ServiceDetails,
	ServiceTitle,
	ServiceWrapper,
	Section,
	ServiceImage,
} from './styled/style';
import service from '../assets/services';

const Service = () => {
	const getImage = (id: string) => {
		switch (id) {
			case 'fleet':
				return service.fleet;
			case 'exterior':
				return service.exterior;
			case 'construction':
				return service.construction;
			case 'commercial':
				return service.commercial;
		}
	};

	return (
		<Section id="service">
			<Header>
				<HeaderLine></HeaderLine>
				<h3>
					<HeaderTitle>
						Services<span>.</span>
					</HeaderTitle>
				</h3>
			</Header>
			<ServiceWrapper>
				{projects.map((project) => (
					<div style={{ opacity: 1, transform: 'none' }}>
						<Reveal width="100%" showGreenAnimation={false}>
							<ServiceImage>
								<img src={getImage(project.id)} alt={`${project.id}`} />
							</ServiceImage>
						</Reveal>
						<ServiceDetails>
							<div
								style={{
									position: 'relative',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								<Reveal width="100%">
									<ServiceTitle>
										<h4>{project.name}</h4>
										<HeaderLine></HeaderLine>
									</ServiceTitle>
								</Reveal>
							</div>
							<div
								style={{
									position: 'relative',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								{project.techStack1 && (
									<Reveal>
										<ServiceTech>{project.techStack1}</ServiceTech>
									</Reveal>
								)}
							</div>
							<div
								style={{
									position: 'relative',
									width: 'fit-content',
									overflow: 'hidden',
								}}
							>
								<Reveal>
									<ServiceDescription>
										{project.decription1}
										{/* <span>&nbsp;{project.link1}</span> */}
									</ServiceDescription>
								</Reveal>
							</div>
							<div
								style={{
									position: 'relative',
									width: '100%',
									overflow: 'hidden',
								}}
							>
								{project.techStack2 && (
									<Reveal>
										<ServiceTech>{project.techStack2}</ServiceTech>
									</Reveal>
								)}
							</div>
							<div
								style={{
									position: 'relative',
									width: 'fit-content',
									overflow: 'hidden',
								}}
							>
								<Reveal>
									<ServiceDescription>
										{project.decription2}
											{/* <span>
												<a	href="/"
													target="_blank"
													rel="nofollow noreferrer"
													style={{
														display: 'flex',
													}}
												>&nbsp;{project.link2}</a>
											</span> */}
									</ServiceDescription>
								</Reveal>
							</div>
						</ServiceDetails>
					</div>
				))}
			</ServiceWrapper>
		</Section>
	);
};

export default Service;
