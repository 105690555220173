import { Reveal } from './Reveal';
import { useEffect, useState } from 'react';
import { navigation } from '../utils/constant';
import { motion } from 'framer-motion';

const Navbar = () => {
	const [activeSection, setActiveSection] = useState('');

	const navVariants = {
		hidden: { opacity: 0, translateX: -30 },
		visible: { opacity: 1, translateX: 0 },
	};

	const handleClick = (id: string) => {
		setActiveSection(id);
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleScroll = () => {
		const scrollPosition = window.scrollY + window.innerHeight / 2;
		const currentSection = navigation.find((section) => {
			const element = document.getElementById(section.id);
			if (element) {
				const { offsetTop, offsetHeight } = element;
				return (
					scrollPosition >= offsetTop &&
					scrollPosition < offsetTop + offsetHeight
				);
			}
			return false;
		});
		setActiveSection(currentSection?.id || '');
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<motion.nav
			className="sidebar"
			initial="hidden"
			animate="visible"
			variants={navVariants}
			transition={{
				type: 'just',
				duration: 0.5,
			}}
		>
			
			{navigation.map((n) => (
				<Reveal
					showGreenAnimation={false}
					main={'leftToRightMain'}
					width={'100%'}
					delay={n.delay}
				>
					<a
						className={`${activeSection === n.id && 'sidebar_selected'}`}
						href={`#${n.id}`}
						onClick={(e) => {
							e.preventDefault();
							handleClick(n.id);
						}}
					>
						{n.display}
					</a>
				</Reveal>
			))}
		</motion.nav>
	);
};

export default Navbar;
