import LogoCarousel from './Logo';
import { Reveal } from './Reveal';
import { Header, HeaderLine, HeaderTitle, Section } from './styled/style';

const Gallery = () => {
	return (
		<Section id="gallery">
			<Header style={{ flexDirection: 'row' }}>
				<h3>
					<Reveal>
						<HeaderTitle>
							Gallery<span>.</span>
						</HeaderTitle>
					</Reveal>
				</h3>
				<HeaderLine></HeaderLine>
			</Header>
			<div className="myHeightClass">
				<LogoCarousel />
			</div>
		</Section>
	);
};

export default Gallery;
