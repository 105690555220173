import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from './styled/style';

import {
	FaTiktok,
	FaFacebookSquare,
	FaInstagram,
} from 'react-icons/fa';
import { FaRegLaugh  } from 'react-icons/fa';
import { BsPatchQuestionFill } from "react-icons/bs";
import { TfiLocationPin, TfiEmail } from "react-icons/tfi";
import { PiPhoneCallLight } from "react-icons/pi";

const Footer = () => {
	return (
		<Box>

			<FooterContainer>
				<Row>
					<Column>
						<Heading>About Us</Heading>
						<FooterLink href="#about">
							<i className="fab fa-facebook-f">
							<span>
									<a	href="#about"
										target="_blank"
										rel="nofollow noreferrer"
										style={{
											display: 'flex',
										}}
									>
										<FaRegLaugh size={30} />   &nbsp; Who are we
									</a>
								</span>
							</i>
						</FooterLink>
						<FooterLink href="#about">
							<i className="fab fa-facebook-f">
							<span>
									<a	href="#about"
										target="_blank"
										rel="nofollow noreferrer"
										style={{
											display: 'flex',
										}}
									>
										<BsPatchQuestionFill size={30} />   &nbsp; Why choose us
									</a>
								</span>
							</i>
						</FooterLink>
						{/* <FooterLink href="#">
							Testimonials
						</FooterLink> */}
					</Column>
					<Column>
						<Heading>Stay Connected</Heading>
						<FooterLink href="https://www.facebook.com/ultrashinemobilewash">
							<i className="fab fa-facebook-f">
							<span>
									<a
										target="_blank"
										rel="nofollow noreferrer"
										href="https://www.facebook.com/ultrashinemobilewash"
										style={{
											display: 'flex',
										}}
									>
										<FaFacebookSquare size={30} />   &nbsp; Facebook
									</a>
								</span>
							</i>
						</FooterLink>
						<FooterLink href="https://www.instagram.com/ultrashinemobilewash">
							<i className="fab fa-instagram">
								<span>
									<a
										target="_blank"
										rel="nofollow noreferrer"
										href="https://www.instagram.com/ultrashinemobilewash"
										style={{
											display: 'flex',
										}}
									>
										<FaInstagram size={30} />   &nbsp; Instagram
									</a>
								</span>
							</i>
						</FooterLink>
						<FooterLink href="https://www.tiktok.com/ultrashinemobilewash">
							<i className="fab fa-tiktok">
								<span>
									<a
										target="_blank"
										rel="nofollow noreferrer"
										href="https://www.tiktok.com/ultrashinemobilewash"
										style={{
											display: 'flex',
										}}
									>
										<FaTiktok size={30} />   &nbsp; Tiktok
									</a>
								</span>
							</i>
						</FooterLink>
					</Column>
					<Column>
						<Heading>Contact Us</Heading>
						<FooterLink>
							<i className="fab fa-tiktok">
								<span>
									<a  href={`mailto:${'info@ultrashinemobilewash.com'}`}
										target="_blank"
										rel="nofollow noreferrer"
										style={{
											display: 'flex',
										}}
									>
										<TfiLocationPin size={30} />   &nbsp; Abbotsford, BC, Canada
									</a>
								</span>
							</i>
						</FooterLink>
						<FooterLink href={`mailto:${'info@ultrashinemobilewash.com'}`}>
							<i className="fab fa-tiktok">
								<span>
									<a  href={`mailto:${'info@ultrashinemobilewash.com'}`}
										target="_blank"
										rel="nofollow noreferrer"
										style={{
											display: 'flex',
										}}
									>
										<TfiEmail  size={27} />   &nbsp; info@ultrashinemobilewash.com
									</a>
								</span>
							</i>
						</FooterLink>
						<FooterLink >
							<i className="fab fa-tiktok">
								<span>
									<a
										href={`tel:${'+1(249)989-2949'}`}
										target="_blank"
										rel="nofollow noreferrer"
										style={{
											display: 'flex',
										}}
									>
										<PiPhoneCallLight size={35} />   &nbsp; +1(249)989-2949
									</a>
								</span>
								<span>
									<a
										href={`tel:${'+1(226)929-4707'}`}
										target="_blank"
										rel="nofollow noreferrer"
										style={{
											display: 'flex',
										}}
									>
										   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +1(226)929-4707
									</a>
								</span>
							</i>
						</FooterLink>
					</Column>
				</Row>
			</FooterContainer>
			<footer className="contactInfo2">
				<div className="">
					<span className="">&copy; {new Date().getFullYear()} Ultra Shine Mobile Wash.</span>
				</div>
        	</footer>
			<footer className="contactInfo2">
				<h3>Powered by: </h3><a className="c2" href="https://www.code-commit.com"><b><u>Code Commit Inc.</u></b></a>
        	</footer>
		</Box>
	);
};
export default Footer;
