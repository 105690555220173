import { useEffect, useState } from 'react';
import { PopupModal } from 'react-calendly';
import { Reveal } from './Reveal';
import { HeaderLink, HeaderWrapper, OutlineButton } from './styled/style';
import { darkColors, lightColors } from '../utils/constant';
import logo from '../assets/logo.png';
import transparentlogo from '../assets/logo_transparent.png';
import contact from '../assets/contact.png';

interface IHeader {
	isDark?: boolean;
}

const Header = ({ isDark = true }: IHeader) => {
	const [isOpen, setIsOpen] = useState(false);
	const [colors, setColors] = useState(darkColors);

	useEffect(() => {
		setColors(!isDark ? darkColors : lightColors);
	}, [isDark]);

	return (
		<HeaderWrapper>
			<HeaderLink>
				<Reveal main="upToDownMain" showGreenAnimation={false} delay={0.2}>
					{isDark ? (
					<Reveal
						showGreenAnimation={false}
						main={'leftToRightMain'}
						width={'100%'}
						delay={0.2}
					>
						<img src={logo} alt="Logo" className="" />
					</Reveal>
					) : (
					<img src={transparentlogo} alt="Logo_tra" className="" />
					)}
				</Reveal>
			</HeaderLink>
			<div className="myFlex">
			<Reveal main="upToDownMain" showGreenAnimation={false} delay={0.3}>
					<Reveal
						showGreenAnimation={false}
						main={'leftToRightMain'}
						width={'100%'}
						delay={0.3}
					>
						<img src={contact} alt="Contact" className="" />
					</Reveal>
				</Reveal>
				<Reveal main="leftToRightMain" slide="rightToLeftSlide" showGreenAnimation={false} delay={0.4}>
					<div className="right-div-2">
						<OutlineButton onClick={() => setIsOpen(true)}>
							FREE Demo!
						</OutlineButton>
					</div>
				</Reveal>
				<PopupModal
					url={`https://calendly.com/code-commit-info`}
					rootElement={document.getElementById('root') as HTMLElement}
					onModalClose={() => setIsOpen(false)}
					open={isOpen}
					pageSettings={{
						primaryColor: colors.brand,
						textColor: colors.text,
						backgroundColor: colors.background,
					}}
				/>
			</div>
		</HeaderWrapper>
	);
};

export default Header;
