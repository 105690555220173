import fleet from './fleet.jpg';
import exterior from './exterior.jpg';
import fleet2 from './fleet2.jpg';
import exterior2 from './exterior2.jpg';

const images = [
	fleet,
	exterior,
	fleet2,
	exterior2,
];
export default images;
