import { useState } from 'react';
import About from './About';
import Contact from './Contact';
import Header from './Header';
import Hero from './Hero';
import Navbar from './Navbar';
import { HomeContainer } from './styled/style';
import { Modal } from './Modal';
import Service from './Service';
import Footer from './Footer';
import Gallery from './Gallery';

const Layout = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<HomeContainer>
			<Navbar />
			<main>
				<Header />
				<Hero setIsModalOpen={setIsModalOpen} />
				<About />
				<Service />
				<Gallery />
				<Contact />
				<Footer />
				<Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
				{/* <div
					style={{
						height: '200px',
						background:
							'linear-gradient(180deg, var(--background), var(--background-dark))',
					}}
				/> */}
			</main>
		</HomeContainer>
	);
};

export default Layout;
