import { useState, useRef } from 'react';
import { Reveal } from './Reveal';
import { darkColors } from '../utils/constant';
import emailjs from '@emailjs/browser';
import {
	ContactLinkedIn,
	ContactWrapper,
	Section,
	Header,
	HeaderTitle,
	HeaderLine,
	OutlineButton,
	ModalTextArea,
	ModalCard,
	ModalContent,
	ImageWrapper,
	ServiceWrapper,
	ModalInput,
} from './styled/style';
import { info } from '../utils/constant';
import { TfiEmail } from "react-icons/tfi";
import { PiPhoneCallLight } from "react-icons/pi";
import { PopupModal } from 'react-calendly';
import contact from '../assets/contact.svg';
import Lottie from 'react-lottie-player';
import sendKite from '../assets/jsonFiles/sendKite.json';

const Contact = () => {
	const form = useRef<HTMLFormElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [colors] = useState(darkColors);
	const [isSending] = useState(false);

	const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// setShowForm(false);
		// setIsSending(true);

		if (form.current)
			emailjs
				.sendForm(
					process.env.REACT_APP_EMAIL_SERVICE_ID as string,
					process.env.REACT_APP_EMAIL_TEMPLATE_ID as string,
					form.current,
					{
						publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
					}
				)
				.then(
					() => {}
				);
	};


	return (
		<Section id="contact">
			<ContactWrapper>
				<Header>
					<HeaderLine></HeaderLine>
						<h3>
							<HeaderTitle>
								Contact<span>.</span>
							</HeaderTitle>
						</h3>
					</Header>
				<Reveal width="100%">
					<ContactLinkedIn>
						Email or call us to get a &nbsp;
						<span>
							<OutlineButton onClick={() => setIsOpen(true)}>
								FREE
							</OutlineButton>
							<PopupModal
								url={`https://calendly.com/code-commit-info`}
								rootElement={document.getElementById('root') as HTMLElement}
								onModalClose={() => setIsOpen(false)}
								open={isOpen}
								pageSettings={{
									primaryColor: colors.brand,
									textColor: colors.text,
									backgroundColor: colors.background,
								}}
							/>
						</span> quote/demo!
						{/* You can also find us on{' '}
						<a
							target="_blank"
							rel="nofollow noreferrer"
							href="https://www.linkedin.com/company/"
						>
							Linkedin{'        '}
						</a> */}
					</ContactLinkedIn>
				</Reveal>
				<Reveal width="100%">
					<a href={`mailto:${info.email}`}>
						<div className="contactInfo">
							<TfiEmail />
							<span>{info.email}</span>
						</div>
					</a>
					<a href={`tel:${info.primaryPhoneCall}`}>
						<div className="contactInfo1">
							<PiPhoneCallLight />
							<span>{info.primaryPhoneDisplay}</span>
						</div>
					</a>
					<a href={`tel:${info.secondaryPhoneCall}`}>
						<div className="contactInfo1">
							<PiPhoneCallLight />
							<span>{info.secondaryPhoneDisplay}</span>
						</div>
					</a>
				</Reveal>
				<Reveal>
					{/* <div>
						<h2>Contact Us for Careers</h2>
							<form className="w-full py-2" ref={form} onSubmit={sendEmail}>
								<ContactServiceWrapper>
									<label htmlFor="from_name">Name:</label>
									<ContactInput name="from_name" type="text" placeholder="Name" />
									<label htmlFor="user_email">Email:</label>
									<ContactInput name="user_email" type="text" placeholder="Email" />
									<label htmlFor="user_phone">Phone:</label>
									<ContactInput name="user_phone" type="text" placeholder="Phone" />
									<label htmlFor="user_subject">Subject:</label>
									<ContactInput
										name="user_subject"
										type="text"
										placeholder="Subject"
									/>
									<label htmlFor="message">Message:</label>
									<ModalTextArea name="message" placeholder="Message" />
									<OutlineButton
										type="submit"
										style={{ margin: '1rem', textAlign: 'center' }}
									>
										Submit
									</OutlineButton>
								</ContactServiceWrapper>
							</form>
					</div> */}
					<ModalCard>
						
						<ImageWrapper>
							<img
								className="projectmodal_modalImage__EWd63"
								src={contact}
								alt="Contact Form"
							/>
						</ImageWrapper>
						<ModalContent>
							{
								<form className="w-full py-2" ref={form} onSubmit={sendEmail}>
									<ServiceWrapper>
										<ModalInput name="from_name" type="text" placeholder="Name" />
										<ModalInput name="user_email" type="text" placeholder="Email" />
										<ModalInput name="user_phone" type="text" placeholder="Phone" />
										<ModalInput
											name="user_subject"
											type="text"
											placeholder="Subject"
										/>
										<ModalTextArea name="message" placeholder="Message" />
										<OutlineButton
											type="submit"
											style={{ margin: '1rem', textAlign: 'center' }}
										>
											Submit
										</OutlineButton>
									</ServiceWrapper>
								</form>
							}
							{isSending && (
								<Lottie loop={isSending} animationData={sendKite} play />
							)}
						</ModalContent>
					</ModalCard>
				</Reveal>
				
			</ContactWrapper>

		</Section>
	);
};

export default Contact;
