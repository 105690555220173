import styled from 'styled-components';

interface ISectionProps {
	withMargin?: boolean;
}

export const Section = styled.section<ISectionProps>`
	position: relative;
	z-index: 10;
	max-width: 1150px;
	margin: auto;
	padding: 9.6rem;
	overflow: hidden;

	margin-bottom: ${(props) => (props.withMargin ? '9.6rem' : '0rem')};

	@media (max-width: 768px) {
		margin-bottom: 4.8rem;
		padding: 7.2rem 2.4rem;
	}
`;

// ------------------- Hero Styles Start ------------------
export const HeroWrapper = styled.div`
	position: relative;
	z-index: 10;
	width: -moz-fit-content;
	width: fit-content;
	margin: 4.8rem 0;
`;

export const HeroTitle = styled.h1`
	font-size: var(--text-2xl);
	font-weight: 900;
	line-height: 1.1;

	span {
		color: var(--brand);
	}

	@media (max-width: 768px) {
		font-size: var(--text-xl);
	}
`;

export const HeroSubTitle = styled.h2`
	font-size: var(--text-md);
	line-height: 1.1;
	margin-top: 1.6rem;
	font-weight: 200;

	span {
		font-weight: 700;
		color: var(--brand);
	}
`;

export const HeroDetail = styled.p`
	margin: 2.4rem 0;
	max-width: 700px;
	font-weight: 200;
`;

export const Button = styled.button`
	outline: none;
	border: none;
	cursor: pointer;
	padding: 1.2rem 2.4rem;
	background: var(--brand);
	color: var(--background-dark);
	font-size: var(--text-sm);
	border-radius: 4px;
	position: relative;
	z-index: 20;
	overflow: hidden;
	transition: opacity 0.2s;

	&:hover {
		opacity: 0.9;
	}
`;

// ------------------- Hero Styles End ------------------

// ------------------- Contact Styles Start ------------------

export const ContactWrapper = styled.div`
	max-width: 700px;
	// margin: auto;
	border-radius: 1.2rem;
`;

export const ContactTitle = styled.h1`
	font-size: var(--text-2xl);
	text-align: center;
	font-weight: 900;
	line-height: 1;

	span {
		color: var(--brand);
	}

	@media (max-width: 768px) {
		font-size: var(--text-xl);
	}
`;

export const ContactLinkedIn = styled.p`
	text-align: center;
	font-weight: 200;
	margin: 2.4rem 0;

	a {
		color: var(--brand);

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		color: var(--brand);
		font-weight: 500;
	}
`;

export const ContactEmail = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	width: -moz-fit-content;
	width: fit-content;
	font-size: var(--text-md);
	margin: auto;
	transition: color 0.25s;

	&:hover {
		color: var(--brand);
	}
`;

// ------------------- Contact Styles End ------------------
// ------------------- Home Styles Start ------------------
export const HomeContainer = styled.div`
	display: grid;
	grid-template-columns: 60px 1fr;
`;

// ------------------- Home Styles End ------------------
// ------------------- Heading Styles Start ------------------
export const HeaderWrapper = styled.header`
	height: calc(45px + 0);
	// padding: 0 3.6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 20;
	background: #fff;
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	font-size: var(--text-md);
	font-weight: 700;

	@media (max-width: 768px) {
		padding: 0 0.5rem;
	}
`;

export const HeaderLink = styled.div`
	display: flex;
	gap: 1.6rem;

	span {
		opacity: 1;
		transform: none;
	}

	a {
		opacity: 0.5;
		position: relative;
		z-index: 20;

		&:hover {
			color: var(--brand);
			opacity: 1;
		}
	}
`;

export const OutlineButton = styled.button`
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0.4rem;
	border: 1px solid var(--brand);
	background: transparent;
	color: var(--brand);
	font-size: var(--text-xxs);
	border-radius: 4px;
	position: relative;
	z-index: 20;
	overflow: hidden;
	transition: color 0.2s;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		background: var(--brand);
		height: 100%;
		width: 100%;
		left: -100%;
		top: -100%;
		transition: left 0.2s, top 0.2s;
	}

	&:hover {
		color: var(--background-dark);
	}

	&:hover:before {
		left: 0;
		top: 0;
	}
`;
//---------------------Footer Styles Start------------------
export const Box = styled.div`
	padding: 5% 2.5%;
	background: transparent;
	position: relative;
	bottom: 0;

	@media (max-width: 1000px) {
		// padding: 70px 30px;
	}
`;

export const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
	/* background: red; */
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 20px;
`;

export const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(185px, 1fr)
	);
	grid-gap: 20px;

	@media (max-width: 1000px) {
		grid-template-columns: repeat(
			auto-fill,
			minmax(200px, 1fr)
		);
	}
`;

export const FooterLink = styled.a`
	//color: #fff;
	margin-bottom: 20px;
	font-size: 18px;
	text-decoration: none;
	width: max-content;

	&:hover {
		color: var(--brand);;
		transition: 200ms ease-in;
	}

	.icon-phone-handset {
  		display: inline-block;
  		width: 24px;
  		height: 24px;
	}
`;

export const FooterLink1 = styled.a`
	//color: #fff;
	margin-bottom: 20px;
	font-size: 18px;
	text-decoration: none;
	width: max-content;

	&:hover {
		color: red;
		transition: 200ms ease-in;
	}

	.icon-phone-handset {
  		display: inline-block;
  		width: 24px;
  		height: 24px;
	}
`;

export const Heading = styled.p`
	font-size: 24px;
	//color: #fff;
	margin-bottom: 40px;
	font-weight: bold;
`;

//---------------------Footer Styles End------------------
// ------------------- About Styles Start ------------------
export const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 2.4rem;
	margin-bottom: 2.4rem;
	flex-direction: row-reverse;
`;

export const HeaderTitle = styled.span`
	font-size: var(--text-xl);
	font-weight: 900;
	text-align: end;

	span {
		color: var(--brand);
	}

	@media (max-width: 768px) {
		font-size: var(--text-lg);
	}
`;

export const HeaderLine = styled.div`
	width: 100%;
	height: 1px;
	background: var(--text);
	opacity: 0.3;
`;

export const AboutWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 300px;
	grid-gap: 2.4rem;
	gap: 2.4rem;

	p {
		margin-bottom: 2.4rem;
		font-weight: 200;
	}

	p.highlightFirstLetter {
		&:first-letter {
			background: var(--background-light);
			padding: 1rem;
			border-radius: 0.4rem;
			font-size: var(--text-md);
			font-weight: 700;
			margin-right: 0.6rem;
			float: left;
		}
	}

	@media (max-width: 900px) {
		grid-template-columns: 1fr;
	}
`;

export const AboutLinks = styled.div`
	//display: flex;
	align-items: center;
	gap: 1.6rem;

	div.linksText {
		display: flex;
		align-items: center;
		gap: 0.8rem;
		font-size: var(--text-sm);
		color: var(--brand);
	}

	div.links {
		display: flex;
		gap: 1.6rem;

		a {
			opacity: 0.5;
			position: relative;
			z-index: 20;

			&:hover {
				color: var(--brand);
				opacity: 1;
			}
		}
	}
`;

export const StatsWrapper = styled.div`
	position: relative;

	.statColumn h4 {
		display: flex;
		align-items: center;
		margin-bottom: 2.4rem;
	}

	.statGrid {
		display: flex;
		flex-wrap: wrap;
		gap: 1.2rem;
		margin-bottom: 4.8rem;

		.chip {
			font-size: var(--text-xs);
			// background: var(--background-light);
			padding: 0.2rem 0.8rem;
			border-radius: 999px;
			color: var(--brand);
		}
	}

	h4 {
		display: flex;
		align-items: center;
		margin-bottom: 2.4rem;

		span {
			font-size: var(--text-md);
			font-weight: 700;
			position: relative;
			margin-left: 0.8rem;
		}
	}
`;
// ------------------- About Styles Start ------------------

export const ContactServiceWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 7fr;
	// border: 1px solid;
	// background: aqua;

	@media (max-width: 768px) {
		grid-template-columns: 2fr;
	}
`;
// ------------------- Service Styles Start ------------------
export const ServiceWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 3.2rem;
	gap: 3.2rem;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const ServiceImage = styled.div`
	width: 100%;
	aspect-ratio: 16/10.5;
	background: var(--service-background);
	cursor: pointer;
	position: relative;
	border-radius: 0.8rem;
	overflow: hidden;

	img {
		width: 85%;
		position: absolute;
		//bottom: 0;
		left: 50%;
		translate: -50% 20%;
		transition: all 0.25s;
		border-radius: 0.4rem;

		&:hover {
			width: 90%;
			rotate: 2deg;
		}
	}
`;

export const ServiceDetails = styled.div`
	margin: 1.6rem 0;
`;

export const ServiceTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 1.2rem;

	h4 {
		font-weight: 700;
		font-size: var(--text-md);
		flex-shrink: 0;
		max-width: calc(100% - 150px);
	}

	a {
		opacity: 0.75;
		transition: opacity 0.25s;

		&:hover {
			opacity: 1;
		}
	}
`;

export const ServiceDescription = styled.p`
	font-weight: 200;

	span {
		display: inline-block;
		font-size: var(--text-xs);
		font-weight: 400;
		color: var(--brand);
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
`;

export const ServiceTech = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1.2rem;
	font-size: var(--text-xs);
	color: var(--brand);
	margin: 0.8rem 0;
`;
//----------------------Porject Style Ends------------------

//----------------------Modal Style Starts--------------------
export const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100000000;
	height: 100vh;
	padding: 4.8rem 1.2rem;
	background: var(--bg-opaque);
	-webkit-backdrop-filter: blur(12px);
	backdrop-filter: blur(12px);
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	cursor: pointer;
`;

export const ModalCard = styled.div`
	width: 100%;
	max-width: 700px;
	height: -moz-fit-content;
	height: fit-content;
	border-radius: 1.2rem;
	overflow: hidden;
	background: #eee;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	cursor: auto;
	padding: 3rem;

	h4 {
		font-size: var(--text-lg);
		text-align: center;
	}
`;

export const ImageWrapper = styled.div`
	img {
		border-radius: 0.8rem;
		width: 100%;
		background-color: var(--service-background);
	}
`;

export const ModalContent = styled.div`
	padding: 2.4rem 0;
`;

export const ModalCloseButton = styled.button`
	background: none;
	border: none;
	color: var(--text);
	font-size: var(--text-md);
	position: absolute;
	top: 1.2rem;
	right: 1.2rem;
	cursor: pointer;
`;

export const ModalInput = styled.input`
	background-color: var(--service-background);
	width: 100%;
	color: var(--background);
	border-radius: 2px;
	padding: 4px 8px;
`;

export const ContactInput = styled.input`

	width: 100%;
	color: var(--background);
	border-radius: 2px;
	padding: 4px 8px;

	.label {
		color: var(--brand);
	}
`;

export const ModalTextArea = styled.textarea`
	background-color: var(--service-background);
	width: 100%;
	color: var(--background);
	border-radius: 2px;
	padding: 4px 8px;
`;

//----------------------Modal Style Ends---------------------
