import { useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import {
	type ISourceOptions,
	MoveDirection,
	OutMode,
	type Container,
} from '@tsparticles/engine';
import { loadSlim } from '@tsparticles/slim';
import './App.css';
import Layout from './components/Layout';

const App = () => {
	const [init, setInit] = useState(false);

	// this should be run only once per application lifetime
	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadSlim(engine);
		}).then(() => {
			setInit(true);
		});
	}, []);

	const particlesLoaded = async (container?: Container): Promise<void> => {
		console.log(container);
	  };

	const options: ISourceOptions = useMemo(
		() => ({
			background: {
				color: {
					value: '#fff',
				},
		  },
		  fpsLimit: 120,
		  interactivity: {
			events: {
				onClick: {
					enable: true,
					mode: 'push',
				},
				onHover: {
					enable: false,
					mode: 'repulse',
				},
			},
			modes: {
			  push: {
				quantity: 4,
			  },
			  repulse: {
				distance: 200,
				duration: 0.4,
			  },
			},
		  },
		  particles: {
			color: {
			  value: '#9684fa',
			},
			links: {
			  color: '#fff',
			  distance: 150,
			  enable: true,
			  opacity: 0.5,
			  width: 1,
			},
			move: {
			  direction: MoveDirection.none,
			  enable: true,
			  outModes: {
				default: OutMode.out,
			  },
			  random: false,
			  speed: 5,
			  straight: false,
			},
			number: {
			  density: {
				enable: true,
			  },
			  value: 80,
			},
			opacity: {
			  value: 0.5,
			},
			shape: {
			  type: "circle",
			},
			size: {
			  value: { min: 4, max: 10 },
			},
		  },
		  detectRetina: true,
		}),
		[]
	);

	if (init) {
		return (
			<>
				<Particles
					id="tsparticles"
					className="fixed w-full h-full top-0 left-0 -z-0"
					particlesLoaded={particlesLoaded}
					options={options}
				/>
				<Layout />
			</>
		);
	}

	return <></>;
};

export default App;
