export const darkColors = {
	background: '#ebecf3',
	'bg-opaque': 'hsl(0deg 0% 100% / 25%)',
	'background-light': '#ebecf3',
	'background-dark': '#ebecf3',
	'service-background': '#111',
	text: '#080808',
	brand: '#6c63ff',
};

export const lightColors = {
	background: '#111',
	'bg-opaque': 'hsla(0, 0%, 7%, 0.25)',
	'background-light': '#232323',
	'background-dark': '#080808',
	'service-background': '#fff',
	text: '#ebecf3',
	brand: '#6c63ff',
};

export const particle = {
	dark: {
		bg: '#fff',
		particleColor: '#111',
		linkColor: '#6c63ff',
		// bg: '#111',
		// particleColor: '#fff',
		// linkColor: '#6c63ff',
	},
	light: {
		bg: '#111',
		particleColor: '#fff',
		linkColor: '#6c63ff',
		// bg: '#fff',
		// particleColor: '#111',
		// linkColor: '#6c63ff',
	},
};

export const projects = [
	{
		id: 'fleet',
		name: 'Commercial Fleet Washing',
		techStack1: 'On-Demand Services',
		techStack2: 'Scheduled Plans',
		decription1: `We understand that unexpected messes happen. Our mobile service is available for emergency cleanups to minimize downtime and maintain operational efficiency.`,
		decription2: `We offer customizable schedules to ensure your fleet is washed regularly, maintaining a clean and polished appearance.`,
		link1:' Book now',
		link2: ' Learn more'
	},
	{
		id: 'exterior',
		name: 'Residential Exterior Cleaning',
		techStack1: 'Driveway and Sidewalk Cleaning',
		techStack2: 'Deck and Patio Cleaning',
		decription1: `Improve the look of your driveway/sidewalk with the removal of dirt, oil stains, mold, and mildew from concrete, asphalt, and paver surfaces using high pressure cleaning.`,
		decription2: `Cleaning and restoring wooden decks, patios, and outdoor furniture to remove dirt, grime, mold, and algae. This can involve adjusting pressure levels to prevent damage to wood surfaces.`,
	},
	{
		id: 'construction',
		name: 'Construction and Heavy Equipment Washing',
		techStack1: 'Exterior Cleaning',
		decription1: `Get your contruction or heavy equipment cleaned by our high-pressure washers by removing heavy dirt, mud, grease, and construction debris and applying degreasers to remove grease, oil, and stubborn residues from the equipment surfaces.`,
		techStack2: 'Specialized Services',
		decription2: `Have your equipment back in shape with the removal of graffiti or paint overspray from equipment surfaces using specialized techniques and products.`,
	},
	{
		id: 'commercial',
		name: 'Commercial Building Cleaning',
		techStack1: 'Parking Lot and Garages',
		decription1: `Remove oil stains and other vehicle fluids from parking lots and garages, restore parking lot striping and markings using our high-pressure washing.`,
		techStack2: 'Exterior Building Washing',
		decription2: `Remove dirt, dust, and stains from building exteriors, remove debris, gum, and stains from sidewalks and pathways to restore brickwork and concrete surfaces.`,
	}
];

export const variant = {
	leftToRightSlide: { hidden: { left: 0 }, visible: { left: '100%' } },
	rightToLeftSlide: { hidden: { right: 0 }, visible: { right: '100%' } },
	downToUpMain: {
		hidden: { opacity: 0, translateY: 90 },
		visible: { opacity: 1, translateY: 0 },
	},
	upToDownMain: {
		hidden: { opacity: 0, translateY: -90 },
		visible: { opacity: 1, translateY: 0 },
	},
	leftToRightMain: {
		hidden: { opacity: 0, translateX: -30 },
		visible: { opacity: 1, translateX: 0 },
	},
	rightToLeftMain: {
		hidden: { opacity: 0, translateX: 30 },
		visible: { opacity: 1, translateX: 0 },
	},
};

export const navigation = [
	{ id: 'hero', display: 'Home', delay: 0.2 },
	{ id: 'about', display: 'About', delay: 0.3 },
	{ id: 'service', display: 'Services', delay: 0.4 },
	{ id: 'gallery', display: 'Gallery', delay: 0.5 },
	{ id: 'contact', display: 'Contact', delay: 0.6 },
];

export const info = {
	email: 'info@ultrashinemobilewash.com',
	primaryPhoneDisplay: '+1 (249) 989-2949',
	primaryPhoneCall: '+1249989249',
	secondaryPhoneDisplay: '+1-(226) 929-4707',
	secondaryPhoneCall: '+12269294707',
};
