import { Reveal } from './Reveal';
import {
	Header,
	HeaderLine,
	HeaderTitle,
	AboutWrapper,
	Section,
	StatsWrapper,
} from './styled/style';

const About = () => {
	return (
		<Section id="about">
			<Header>
				<HeaderLine></HeaderLine>
				<h3>
					<HeaderTitle>
						About<span>.</span>
					</HeaderTitle>
				</h3>
			</Header>
			<AboutWrapper>
				<div>
					<Reveal>
						<p>
							Welcome to Ultra Shine Mobile Wash, where we redefine the standard for mobile vehicle cleaning services. 
							With a commitment to excellence and a passion for cleanliness, we specialize in delivering top-quality 
							washing and detailing solutions right to your location.
						</p>
					</Reveal>
					<Reveal>
						<p>
							At Ultra Shine, we understand that your vehicles are more than just means of transportation—they 
							represent your brand and your commitment to quality. That’s why we employ a team of dedicated professionals 
							who use the latest techniques and eco-friendly products to ensure that every vehicle we touch shines with perfection.
						</p>
					</Reveal>
					<Reveal>
						<p>
							Our mobile service is designed for convenience and efficiency. Whether you have a single vehicle or a large fleet, 
							our fully equipped units are ready to deliver a superior clean services wherever you are. We take pride in our work and 
							guarantee customer satisfaction with every wash.
						</p>
					</Reveal>
					<Reveal>
						<p>
							Experience the difference with Ultra Shine Mobile Wash. Let us elevate your vehicle’s appearance and maintain its 
							value with our meticulous care. Contact us today to schedule your next wash and see why we’re the preferred choice 
							for mobile vehicle cleaning.
						</p>
					</Reveal>
					<Reveal>
						<StatsWrapper>
							<Reveal slide={'rightToLeftSlide'}>
							<h4>
								<span><a href='/service#service'><u>Learn more about our services</u></a></span>
							</h4>
							</Reveal>
						</StatsWrapper>
					</Reveal>
				</div>
				<StatsWrapper>
					<Reveal slide={'rightToLeftSlide'}>
						<div>
							<h4>
								<svg
									stroke="currentColor"
									fill="currentColor"
									stroke-width="0"
									viewBox="0 0 1024 1024"
									color="var(--brand)"
									style={{ color: 'var(--brand)' }}
									height="2.4rem"
									width="2.4rem"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zM288 421a48.01 48.01 0 0 1 96 0 48.01 48.01 0 0 1-96 0zm224 272c-85.5 0-155.6-67.3-160-151.6a8 8 0 0 1 8-8.4h48.1c4.2 0 7.8 3.2 8.1 7.4C420 589.9 461.5 629 512 629s92.1-39.1 95.8-88.6c.3-4.2 3.9-7.4 8.1-7.4H664a8 8 0 0 1 8 8.4C667.6 625.7 597.5 693 512 693zm176-224a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path>
								</svg>
								<span>Why choose us?</span>
							</h4>
							<div className="statGrid">
							<span className="chip">Exceptional Quality and Expertise</span>
								<p>Our trained professionals use industry-leading techniques and high-quality products to achieve a pristine shine every time.</p>
								
								{/* <span className="chip">Convenient Mobile Service</span>
								<p>Enjoy the convenience of having your vehicles washed and detailed at your location.</p> */}
								
								<span className="chip">Eco-Friendly Practices</span>
								<p>Our cleaning products are gentle on the environment yet powerful enough to deliver outstanding results.</p>

								<span className="chip">Customer Satisfaction Guarantee</span>
								<p>We strive to exceed expectations with every service, ensuring that vehicles not only look great but also reflect positively on your brand.</p>

								{/* <span className="chip">Competitive Pricing</span>
								<p>We offer competitive rates for our premium services, making professional vehicle cleaning accessible and affordable for businesses and individuals alike.</p> */}

								<span className="chip">Trusted Reputation</span>
								<p>Countless satisfied customers trust us for reliability, professionalism, and excellence in service for their cleaning needs.</p>
							</div>
						</div>
						</Reveal>
				</StatsWrapper>
			</AboutWrapper>
		</Section>
	);
};

export default About;
